// @flow

import * as React from 'react';

import { AddToBasketButton, Box, Flexbox, Price, RTEText } from '../common';
import type { TAlbum, TPageContent } from '../../types';

import CardCollectorsClubBinders from '../CardCollectorsClub/CardCollectorsClubBinders';
import { PageHelpers } from '../../helpers';
import ProductListTable from '../ProductListTable';
import { useAlbums } from '../../hooks/albums';
import { useProduct } from '../../hooks/products/useProducts';

type Props = {
  content?: TPageContent,
};

const TWEASERS_ID = 9056;

function AlbumHome(props: Props) {
  const { product: tweasers } = useProduct(TWEASERS_ID);
  const [albums] = useAlbums();

  function _getHeading(): React.Node {
    const { content } = props;
    let intro = PageHelpers.getContent(content, 'Heading');

    return <RTEText content={intro} />;
  }

  function _getAlbums(albumsInternal: Array<TAlbum>): React.Node {
    return (
      <div className="prodlist" id="linelist">
        {_getTable(albumsInternal, 'albums')}
      </div>
    );
  }

  function _getLeaves(leaves: Array<TAlbum>): React.Node {
    const { content } = props;
    let intro = PageHelpers.getContent(content, 'Extra Leaves Intro');

    return (
      <React.Fragment>
        <RTEText content={intro} />
        <div className="prodlist" id="linelist">
          {_getTable(leaves, 'leaves')}
        </div>
      </React.Fragment>
    );
  }

  function _getAccessories(accessories: Array<TAlbum>): React.Node {
    const { content } = props;
    let intro = PageHelpers.getContent(content, 'Other Album Accessories');
    return (
      <React.Fragment>
        <RTEText content={intro} />
        <div className="prodlist" id="linelist">
          {_getSimpleTable(
            accessories,
            'Standard Album Accessories',
            'accessories',
          )}
        </div>
      </React.Fragment>
    );
  }

  function _getLTAlbums(ltAlbums: Array<TAlbum>): React.Node {
    const { content } = props;
    let intro = PageHelpers.getContent(content, 'LT Album');

    return (
      <React.Fragment>
        <RTEText content={intro} />
        <div className="prodlist" id="linelist">
          {_getSimpleTable(
            ltAlbums,
            'Large Trade Card Album & Leaves',
            'ltAlbums',
          )}
        </div>
      </React.Fragment>
    );
  }

  function _getLuxuryBinders(luxuryBinders: Array<TAlbum>): React.Node {
    const { content } = props;
    let intro = PageHelpers.getContent(content, 'Luxury Binder');

    return (
      <React.Fragment>
        <RTEText content={intro} />
        <div className="prodlist" id="linelist">
          {_getTable(luxuryBinders, 'luxuryBinders')}
        </div>
      </React.Fragment>
    );
  }

  function _getLuxuryLeaves(luxuryLeaves: Array<TAlbum>): React.Node {
    const { content } = props;
    let intro = PageHelpers.getContent(content, 'Luxury Leaves');

    return (
      <React.Fragment>
        <RTEText content={intro} />
        <div className="prodlist" id="linelist">
          {_getTable(luxuryLeaves, 'luxuryLeaves')}
        </div>
      </React.Fragment>
    );
  }

  function _getWants(wants: Array<TAlbum>): React.Node {
    const { content } = props;
    let intro = PageHelpers.getContent(content, 'Accessories Intro');

    return (
      <React.Fragment>
        <RTEText content={intro} />
        <div className="prodlist" id="linelist">
          {_getSimpleTable(wants, 'Wants List', 'wants')}
        </div>
      </React.Fragment>
    );
  }

  function _getTweasers(): React.Node {
    const { content } = props;
    let intro =
      PageHelpers.getContent(content, 'Tweasers') || '<p>Tweasers</p>';

    return (
      <React.Fragment>
        <RTEText content={intro} />
        {tweasers && <ProductListTable products={[tweasers]} />}
      </React.Fragment>
    );
  }

  function _getWrappings(wrappings: Array<TAlbum>): React.Node {
    const { content } = props;
    let intro = PageHelpers.getContent(content, 'Wrapping Intro');

    return (
      <React.Fragment>
        <RTEText content={intro} />
        <div className="prodlist" id="linelist">
          {_getSimpleTable(wrappings, 'Clear Wrapping Strips', 'wrappings')}
        </div>
      </React.Fragment>
    );
  }

  function _getTable(prods: Array<TAlbum>, type: string): React.Node {
    return (
      <table id="prodlist">
        <thead>
          <tr valign="top">
            <td>Page Ref.</td>
            <td>Album Size</td>
            {type !== 'luxuryBinders' && <td>Pockets per page</td>}
            {type !== 'luxuryBinders' && <td>Pocket size</td>}
            <td width="60">Price</td>
            <td width="120" align="center">
              Basket
            </td>
          </tr>
        </thead>
        <tbody>
          {prods.map((prod) => {
            return (
              <tr key={prod.name}>
                <td valign="top">{prod.ref}</td>
                <td valign="top">{prod.suitableFor}</td>
                {type !== 'luxuryBinders' && (
                  <td valign="top">{prod.perPage}</td>
                )}
                {type !== 'luxuryBinders' && <td valign="top">{prod.size}</td>}
                <td valign="top">
                  <Price price={prod.price} />
                </td>
                <td valign="top" align="center" className="highlight">
                  <AddToBasketButton
                    stateKey={prod.id}
                    type={type}
                    hash={prod.hash}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  function _getBinders(): React.Node {
    let intro = PageHelpers.getContent(props.content, 'Binders Heading');
    return (
      <span>
        <RTEText content={intro} />
        <CardCollectorsClubBinders ccc={props.ccc} />
      </span>
    );
  }

  function _getSimpleTable(
    prods: Array<TAlbum>,
    cellHeading: string,
    type: string,
  ): React.Node {
    return (
      <table id="prodlist">
        <thead>
          <tr valign="top">
            <td>{cellHeading}</td>
            <td width="60">Price</td>
            <td width="120" align="center">
              Basket
            </td>
          </tr>
        </thead>
        <tbody>
          {prods.map((prod) => {
            return (
              <tr key={prod.hash}>
                <td valign="top">{prod.ref}</td>
                <td valign="top">
                  <Price price={prod.price} />
                </td>
                <td valign="top" align="center" className="highlight">
                  <AddToBasketButton
                    stateKey={prod.id}
                    type={type}
                    hash={prod.hash}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  if (props.content == null) {
    return null;
  }

  return (
    <Flexbox flexDirection="column">
      <Flexbox flexGrow={1}>
        <Box highlight={true}>
          <span>
            {_getHeading()}
            {_getAlbums(albums.albums)}
            {_getLeaves(albums.leaves)}
            {_getAccessories(albums.accessories)}
            {_getLTAlbums(albums.ltAlbums)}
            {_getLuxuryBinders(albums.luxuryBinders)}
            {_getLuxuryLeaves(albums.luxuryLeaves)}
            {/* {_getWants(albums.wants)} */}
            {_getTweasers()}
            {_getWrappings(albums.wrappings)}
            {_getBinders()}
          </span>
        </Box>
      </Flexbox>
    </Flexbox>
  );
}

export default AlbumHome;
